import React, { FC, ReactElement } from 'react';
import { useTheme } from '@cherre-frontend/ui';

import {
  DescriptionContainer,
  DescriptionContent,
  OverviewNextStepsCardContainer,
  Title,
  ViewButton,
} from './styles';
import { OverviewNextStepsCardProps } from './model';
import { ArrowForwardIconCustom } from '../OverviewNextStepsSection/styles';

const OverviewNextStepsCard: FC<OverviewNextStepsCardProps> = ({
  onClick,
  title,
  description,
}): ReactElement => {
  const theme = useTheme();

  return (
    <OverviewNextStepsCardContainer theme={theme}>
      <Title>{title}</Title>

      <DescriptionContainer>
        <DescriptionContent>{description}</DescriptionContent>

        <ViewButton onClick={onClick}>
          View <ArrowForwardIconCustom />
        </ViewButton>
      </DescriptionContainer>
    </OverviewNextStepsCardContainer>
  );
};

export default OverviewNextStepsCard;
