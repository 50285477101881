import { ArrowForwardIcon, styled } from '@cherre-frontend/ui';

export const OverviewNextStepsSectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const OverviewNextStepsCardsContainer = styled('div')`
  display: flex;
  width: 100%;
  gap: 32px;
`;

export const Title = styled('h5')`
  font-weight: 700;
  font-size: 20px;
  line-height: 24.5px;
  letter-spacing: 1%;
  color: #000000;
  margin: 0px;
`;

export const ArrowForwardIconCustom = styled(ArrowForwardIcon)`
  height: 15px;
  width: 15px;
`;
