import React, { FC, ReactElement, useCallback } from 'react';
import {
  OverviewNextStepsCardsContainer,
  OverviewNextStepsSectionContainer,
  Title,
} from './styles';
import OverviewNextStepsCard from '../OverviewNextStepsCard';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { ConnectorDetailsTabValues } from '../../..';
import { connectorDetailsTabState } from 'src/products/connector/recoil';
import { useHistory } from 'react-router';

const OverviewNextStepsSection: FC = (): ReactElement => {
  const [, setTab] = useCherreState(connectorDetailsTabState);
  const history = useHistory();

  const handleRedirectToDataValidationTab = useCallback(() => {
    setTab(ConnectorDetailsTabValues.VALIDATION);
  }, []);

  const handleRedirectToInventoryPage = useCallback(() => {
    history.push('/connectors/inventory');
  }, []);

  return (
    <OverviewNextStepsSectionContainer>
      <Title>Next Steps</Title>
      <OverviewNextStepsCardsContainer>
        <OverviewNextStepsCard
          onClick={handleRedirectToInventoryPage}
          title='Go to Connector Inventory'
          description='View all your connectors and their current statuses on the connector overview page. You can view and edit details for your newly created connector as well.'
        />
        <OverviewNextStepsCard
          onClick={handleRedirectToDataValidationTab}
          title='Validation Rules'
          description='Create or view validation rules for your connector. You can proactively enhance the reliability and accuracy of your incoming data by setting up checking for completeness, validating data types, enforcing range constraints and more.'
        />
      </OverviewNextStepsCardsContainer>
    </OverviewNextStepsSectionContainer>
  );
};

export default OverviewNextStepsSection;
