import { Button, styled } from '@cherre-frontend/ui';

export const OverviewNextStepsCardContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
`;

export const Title = styled('h6')`
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #000000;
  margin: 0px;
`;

export const DescriptionContainer = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const DescriptionContent = styled('p')`
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: 1%;
  color: #212121;
  margin: 0px;
`;

export const ViewButton = styled(Button)`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;

  color: #df2467;

  &:hover {
    background-color: transparent;
  }

  & span {
    display: flex;
    gap: 6px;
    align-items: center;
  }
`;
